<template>
  <div
    class="grid-container mx-auto"
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
    ]"
  >
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14 leading-10"
      :class="layoutClasses"
    >
      {{ title }}
    </h2>
    <div :class="layoutClasses">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 gap-6"
        :class="[
          options.format === 'text' ? 'md:grid-cols-2' : 'md:grid-cols-3',
        ]"
      >
        <div
          v-for="(fact, index) in factFigures"
          :key="index"
          class="text-center p-4"
        >
          <div
            class="font-bold"
            :class="[
              options.colorPalette,
              options.format === 'text'
                ? 'text-5xl md:text-7xl'
                : 'text-6xl md:text-8xl',
            ]"
          >
            {{ fact.number }}
          </div>
          <div class="mt-3 font-bold text-xl md:text-2xl">
            {{ fact.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphInfoBoxFragment } from '#graphql-operations'

defineProps<{
  title?: string
  factFigures?: ParagraphInfoBoxFragment['factFigures']
}>()

const { options } = defineBlokkli({
  bundle: 'infobox',
  globalOptions: ['spacing'],
  options: {
    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'sand',
      displayAs: 'colors',
      options: {
        sand: { label: 'Sand', class: 'bg-sand' },
        violett: { label: 'Violett', class: 'bg-violett' },
      },
    },
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'large',
      displayAs: 'icons',
      options: {
        big: { label: 'large', icon: 'icon-blokkli-option-large' },
        text: { label: 'medium', icon: 'icon-blokkli-option-medium' },
      },
    },
  },
})

const layoutClasses = computed(() => {
  if (options.value.format === 'text') {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  }
  return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
})
</script>

<style lang="postcss">
.sand {
  @apply text-sand;
}
.violett {
  @apply text-violett;
}
</style>
