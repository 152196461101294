<template>
  <div :class="paragraphClassList">
    <div
      :class="{
        'col-span-12': options.inContainer,
      }"
    >
      <IframeResizer
        license="GPLv3"
        :src="src"
        :title="title"
        :referrerpolicy="referrerPolicy"
        :style="iframeStyle"
        :class="{ 'pointer-events-none': isEditing }"
        class="w-full min-h-[300px]"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import IframeResizer from '@iframe-resizer/vue/iframe-resizer.vue'
import '@iframe-resizer/child'
import type { ParagraphIframeFragment } from '#graphql-operations'

const { isEditing, options } = defineBlokkli({
  bundle: 'iframe',
  globalOptions: ['spacing'],
  options: {
    inContainer: {
      type: 'checkbox',
      label: 'Container',
      default: true,
    },
  },
})

const props = defineProps<{
  url?: ParagraphIframeFragment['url']
  title?: ParagraphIframeFragment['title']
  referrerPolicy?: ParagraphIframeFragment['referrerPolicy']
  height?: ParagraphIframeFragment['height']
}>()

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  if (options.value.inContainer) {
    classList.push('grid-container')
  }

  return classList
})

const src = computed(() => props.url?.uri?.path || '')
const title = computed(() => props.title || '')
const referrerPolicy = computed(() => props.referrerPolicy || '')
const iframeStyle = computed(() => {
  if (props.height) {
    return { height: `${props.height}px` }
  }
  return {}
})
</script>
