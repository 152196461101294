<template>
  <div
    class="container mx-auto"
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
    ]"
  >
    <div>
      <div v-if="tableData.caption" class="grid-container">
        <h2
          class="text-3xl md:text-4xl mb-10 lg:mb-14 leading-10 col-span-4 sm:col-span-6 md:col-start-8 md:col-span-8 lg:col-start-2 lg:col-span-10"
        >
          {{ tableData.caption }}
        </h2>
      </div>
      <ParagraphChartBar
        v-if="options.chartType === 'bar' && tableData.rows?.length"
        :table="tableData.rows"
        :trend-line="trendLine?.rows"
        :color-palette="options.colorPalette"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :values-on-hover="options.valuesOnHover && !isPrint"
        :decimal-points="options.decimalPoints"
        :axis-labels="axisLabels"
        :is-hidden="options.isHidden"
        :is-stacked="options.isStacked"
        :format="options.format"
        :is-trendline="options.isTrendline"
      />
      <ParagraphChartBarHorizontal
        v-if="options.chartType === 'barHorizontal' && tableData.rows?.length"
        :table="tableData.rows"
        :distribution="options.barDistribution"
        :color-palette="options.colorPalette"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :values-on-hover="options.valuesOnHover && !isPrint"
        :decimal-points="options.decimalPoints"
        :axis-label="axisLabels?.[0]"
        :format="options.format"
        :is-hidden="options.isHidden"
      />
      <ParagraphChartLine
        v-else-if="options.chartType === 'line' && tableData.rows?.length"
        :table="tableData.rows"
        :color-palette="options.colorPalette"
        :padding-top-multiplicator="options.linePaddingTop"
        :padding-bottom-multiplicator="options.linePaddingBottom"
        :padding-left-override="options.linePaddingLeft"
        :axis-labels="axisLabels"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :decimal-points="options.decimalPoints"
        :format="options.format"
        :is-hidden="options.isHidden"
      />
      <div v-if="copyright" class="mt-4 text-xs uppercase text-gray-700">
        {{ copyright }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphChartFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    copyright?: string
    tableData?: ParagraphChartFragment['tableData']
    trendLine?: ParagraphChartFragment['trendLine']
    axisLabels?: string[]
    valuePrefix?: string
    valueSuffix?: string
  }>(),
  {
    copyright: '',
    footnotes: () => [],
    axisLabels: () => [],
    tableData: () => {
      return {
        rows: [],
      }
    },
    trendline: () => {
      return {
        rows: [],
      }
    },
  },
)

const { options } = defineBlokkli({
  bundle: 'chart',
  globalOptions: ['spacing'],
  options: {
    chartType: {
      type: 'radios',
      label: 'Diagrammtyp',
      default: 'barHorizontal',
      displayAs: 'icons',
      options: {
        barHorizontal: {
          label: 'Balken Horizontal',
          icon: 'icon-blokkli-option-chart-bar-horizontal',
        },
        bar: {
          label: 'Balken',
          icon: 'icon-blokkli-option-chart-bar',
        },
        line: { label: 'Linie', icon: 'icon-blokkli-option-chart-line' },
      },
    },

    decimalPoints: {
      type: 'number',
      label: 'Kommastellen',
      default: 1,
      min: 0,
      max: 5,
    },

    linePaddingBottom: {
      type: 'range',
      label: 'Padding (unten)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    linePaddingTop: {
      type: 'range',
      label: 'Padding (oben)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    linePaddingLeft: {
      type: 'range',
      label: 'Padding (links)',
      default: 0,
      min: 0,
      max: 200,
      step: 1,
    },

    rotationOffset: {
      type: 'range',
      label: 'Rotation',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'sand',
      displayAs: 'colors',
      options: {
        sand: { label: 'Sand', class: 'bg-sand' },
        violett: { label: 'Violett', class: 'bg-violett' },
      },
    },

    barDistribution: {
      type: 'radios',
      label: 'Verteilung',
      default: 'largest',
      options: {
        percentage: 'Prozentual',
        largest: 'Grösster Wert',
      },
    },

    valuesOnHover: {
      type: 'checkbox',
      label: 'Werte on Hover',
      default: false,
    },

    isStacked: {
      type: 'checkbox',
      label: 'Stacked',
      default: false,
    },

    isTrendline: {
      type: 'checkbox',
      label: 'Trendline',
      default: false,
    },

    isHidden: {
      type: 'checkbox',
      label: 'Verstecken',
      default: false,
    },

    format: {
      type: 'radios',
      label: 'Format',
      default: 'large',
      displayAs: 'icons',
      options: {
        small: { label: 'small', icon: 'icon-blokkli-option-small' },
        large: { label: 'large', icon: 'icon-blokkli-option-large' },
      },
    },
  },

  editor: {
    determineVisibleOptions: (ctx) => {
      if (ctx.options.chartType === 'bar') {
        return [
          'isHidden',
          'spacing',
          'chartType',
          'colorPalette',
          'valuesOnHover',
          'decimalPoints',
          'isStacked',
          'isTrendline',
          'format',
        ]
      } else if (ctx.options.chartType === 'barHorizontal') {
        return [
          'isHidden',
          'spacing',
          'chartType',
          'barDistribution',
          'colorPalette',
          'valuesOnHover',
          'decimalPoints',
          'format',
        ]
      } else if (ctx.options.chartType === 'line') {
        return [
          'isHidden',
          'spacing',
          'chartType',
          'colorPalette',
          'linePaddingTop',
          'linePaddingBottom',
          'linePaddingLeft',
          'decimalPoints',
          'format',
        ]
      }
      return ['isHidden', 'chartType']
    },
  },
})

const { isPrint } = useViewport()
</script>
