<template>
  <div
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
    ]"
    class="grid-container mx-auto"
  >
    <h2
      v-blokkli-editable:field_title
      :class="layoutClasses"
      class="text-3xl md:text-4xl mb-10 lg:mb-14 leading-10"
    >
      {{ title }}
    </h2>
    <div :class="layoutClasses" class="relative w-full">
      <div
        v-for="(milestone, index) in milestones"
        :key="index"
        class="milestone flex flex-col items-center"
        :class="[
          options.format === 'text'
            ? 'milestone-small'
            : 'before:content-[\'\']',
        ]"
      >
        <div
          class="milestone-content flex items-center relative md:w-full mt-8"
          :class="[
            options.format === 'text'
              ? 'flex-row md:justify-between'
              : 'flex-col',
          ]"
        >
          <h2
            class="text-5xl md:text-7xl font-bold"
            :class="[
              options.format === 'text' ? 'text-4xl md:text-6xl font-bold' : '',
              options.colorPalette,
            ]"
          >
            {{ milestone.year }}
          </h2>
          <div
            :class="[
              options.format === 'text'
                ? ''
                : 'size-4 bg-white border-2 border-gray-300 rounded-full hidden md:flex absolute left-[18rem] top-1/2 -translate-y-1/2 md:before:content-[\'\']',
            ]"
            class="milestone-content-circle"
          />
          <div
            :class="[
              options.format === 'text'
                ? ''
                : 'md:absolute md:w-[35rem] md:left-0 md:top-1/2 md:-translate-y-1/2 md:before:content-[\'\']',
            ]"
            class="milestone-content-text md:flex md:flex-row md:items-center md:justify-start"
          >
            <div
              class="mt-2 text-gray-700 leading-relaxed flex flex-col"
              v-html="milestone.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMilestonesFragment } from '#graphql-operations'

defineProps<{
  title?: string
  milestones?: ParagraphMilestonesFragment['milestones']
}>()

const { options } = defineBlokkli({
  bundle: 'milestones',
  globalOptions: ['spacing'],
  options: {
    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'sand',
      displayAs: 'colors',
      options: {
        sand: { label: 'Sand', class: 'bg-sand' },
        violett: { label: 'Violett', class: 'bg-violett' },
      },
    },
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'large',
      displayAs: 'icons',
      options: {
        big: { label: 'large', icon: 'icon-blokkli-option-large' },
        text: { label: 'medium', icon: 'icon-blokkli-option-medium' },
      },
    },
  },
})

const layoutClasses = computed(() => {
  if (options.value.format === 'text') {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  }
  return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
})
</script>

<style lang="postcss">
.milestone {
  &:before {
    @apply hidden;
  }

  & ~ .milestone {
    &:before {
      @apply w-0.5 h-20 bg-gray-300 flex mt-8;
    }
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  .milestone-content-circle {
    &:before {
      @apply w-22 h-0.5 bg-gray-300 flex order-3 ml-8;
    }
  }

  .milestone-content-text {
    &:before {
      @apply md:w-20 lg:w-36 h-0.5 bg-gray-300 flex order-3 ml-8;
    }

    > div {
      @apply md:w-[17rem];
    }
  }

  &:not(.milestone-small) {
    .milestone-content-text {
      &:before {
        @apply md:content-[''];
      }
    }
  }

  &.milestone-small {
    .milestone-content {
      h2 {
        @apply md:w-[17rem];
      }
    }
    .milestone-content-circle {
      @apply flex flex-row-reverse before:content-[''] after:content-[''] mx-5 md:mx-0;
      &:before {
        @apply size-4 bg-white border-2 border-gray-300 rounded-full block ml-0;
      }
      &:after {
        @apply w-8 md:w-22 h-0.5 bg-gray-300 block order-3 ml-0 mt-2;
      }
    }
    &:nth-child(even) {
      .milestone-content-circle {
        @apply flex-row;
      }
      .milestone-content {
        @apply flex-row-reverse;
      }
    }
  }

  &:nth-child(even) {
    h2 {
      @apply text-gray-500;
    }

    .milestone-content {
      div {
        @apply right-[18rem] left-auto;
      }
      .milestone-content-text {
        @apply md:right-0 md:left-auto md:justify-end;

        &:before {
          @apply flex order-none mr-8;
        }
      }
    }
  }

  &:last-child {
    @apply pb-20;
  }
}
</style>
