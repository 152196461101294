<template>
  <div
    v-if="text"
    class="paragraph-text text-lg lg:text-xl my-6"
    :class="paragraphClassList"
  >
    <div
      v-blokkli-editable:field_text="{ type: 'frame' }"
      class="ck-content hyphens-auto"
      :class="{
        'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8':
          !parentType,
      }"
      @click="onClick"
      v-html="text"
    />
  </div>
</template>

<script lang="ts" setup>
defineProps<{ text?: string }>()

const { onClick } = useClickTriggerProxy()

const { options, parentType } = defineBlokkli({
  bundle: 'text',
  editor: {
    addBehaviour: 'no-form',
    editTitle: (el) => el.textContent,
    getDraggableElement: (el) => el.querySelector('.ck-content'),
    determineVisibleOptions: (ctx) => {
      if (!ctx.parentType) {
        return ['spacing']
      }
      return []
    },
  },
  globalOptions: ['spacing'],
})

const paragraphClassList = computed(() => {
  if (parentType.value === 'carousel') {
    return []
  }

  if (
    parentType.value === 'accordeon' ||
    parentType.value === 'call_to_action_section'
  ) {
    return ['mb-5']
  }

  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  if (!parentType.value) {
    classList.push('grid-container')
  }

  return classList
})
</script>
