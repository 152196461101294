<template>
  <div class="grid-container">
    <div
      class="col-span-4 sm:col-span-6 md:col-start-8 md:col-span-8 lg:col-start-2 lg:col-span-10"
      :class="[
        options.spacing === 'small' ? 'py-6 lg:py-10' : '',
        options.spacing === 'large' ? 'py-12 lg:py-20' : '',
      ]"
    >
      <figure
        v-if="structuredTable || isEditing"
        @keydown.enter.capture="onClick"
      >
        <figcaption
          v-if="structuredTable?.caption"
          class="font-bold text-lg lg:text-2xl mb-10 lg:mb-32"
          v-html="structuredTable.caption"
        />
        <StructuredTable
          v-if="structuredTable"
          v-bind="structuredTable"
          :id="uuid"
          :mobile-style="options.mobileTableStyle"
          :sticky="options.sticky"
          :equalize-widths="options.equalizeWidths"
        />
      </figure>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'

const { onClick } = useClickTriggerProxy()

const { options, uuid, isEditing } = defineBlokkli({
  bundle: 'table',
  globalOptions: ['spacing'],
  options: {
    equalizeWidths: {
      type: 'checkbox',
      label: 'Alle Spalten gleich breit',
      default: true,
    },
    mobileTableStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'horizontal',
      displayAs: 'icons',
      options: {
        vertical: {
          label: 'Vertikal',
          icon: 'icon-blokkli-option-table-vertical',
        },
        horizontal: {
          label: 'Horizontal',
          icon: 'icon-blokkli-option-table-horizontal',
        },
      },
    },
    sticky: {
      type: 'radios',
      label: 'Sticky',
      default: 'none',
      options: {
        none: '-',
        top: 'Oben',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
    determineVisibleOptions: (ctx) => {
      if (ctx.options.mobileTableStyle === 'vertical') {
        return ['equalizeWidths', 'mobileTableStyle', 'spacing']
      }

      return ['equalizeWidths', 'mobileTableStyle', 'sticky', 'spacing']
    },
  },
})

defineProps<{
  structuredTable: ParagraphTableFragment['structuredTable']
}>()
</script>
