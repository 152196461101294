
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_from_library_index from '/builds/bazinga/sv-group/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_accordeon_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Accordeon/index.vue'
import BlokkliComponent_button_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Button/index.vue'
import BlokkliComponent_call_to_action_section_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/CallToActionSection/index.vue'
import BlokkliComponent_carousel_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Carousel/index.vue'
import BlokkliComponent_chart_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Chart/index.vue'
import BlokkliComponent_contact_box_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/ContactBox/index.vue'
import BlokkliComponent_divider_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Divider/index.vue'
import BlokkliComponent_icon_text_item_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/IconTextItem/index.vue'
import BlokkliComponent_icon_text_list_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/IconTextList/index.vue'
import BlokkliComponent_iframe_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Iframe/index.vue'
import BlokkliComponent_image_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_infobox_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Infobox/index.vue'
import BlokkliComponent_media_list_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/MediaList/index.vue'
import BlokkliComponent_milestones_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Milestones/index.vue'
import BlokkliComponent_quote_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_table_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_teaser_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Teaser/index.vue'
import BlokkliComponent_teaser_list_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/TeaserList/index.vue'
import BlokkliComponent_teaser_with_external_link_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/TeaserWithExternalLink/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_text_image_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/TextImage/index.vue'
import BlokkliComponent_video_index from '/builds/bazinga/sv-group/frontend/components/Paragraph/Video/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/sv-group/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_from_library: BlokkliComponent_from_library_index,
  block_accordeon: BlokkliComponent_accordeon_index,
  block_button: BlokkliComponent_button_index,
  block_call_to_action_section: BlokkliComponent_call_to_action_section_index,
  block_carousel: BlokkliComponent_carousel_index,
  block_chart: BlokkliComponent_chart_index,
  block_contact_box: BlokkliComponent_contact_box_index,
  block_divider: BlokkliComponent_divider_index,
  block_icon_text_item: BlokkliComponent_icon_text_item_index,
  block_icon_text_list: BlokkliComponent_icon_text_list_index,
  block_iframe: BlokkliComponent_iframe_index,
  block_image: BlokkliComponent_image_index,
  block_infobox: BlokkliComponent_infobox_index,
  block_media_list: BlokkliComponent_media_list_index,
  block_milestones: BlokkliComponent_milestones_index,
  block_quote: BlokkliComponent_quote_index,
  block_table: BlokkliComponent_table_index,
  block_teaser: BlokkliComponent_teaser_index,
  block_teaser_list: BlokkliComponent_teaser_list_index,
  block_teaser_with_external_link: BlokkliComponent_teaser_with_external_link_index,
  block_text: BlokkliComponent_text_index,
  block_text_image: BlokkliComponent_text_image_index,
  block_video: BlokkliComponent_video_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_Test from '/builds/bazinga/sv-group/frontend/components/Paragraph/BlokkliFragment/FooBar/index.vue'

const globalFragments: Record<string, any> = {
  fragment_Test: BlokkliFragmentComponent_Test
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {
  "block_view": "rare",
  "block_webform": "rare"
}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
