<template>
  <div class="grid-container">
    <div
      :class="[
        isHidden === true ? 'bk-paragraph-region-affected' : '',
        gridContainerSize,
      ]"
    >
      <ChartLegend
        :items="dataPoints"
        :colors="filteredColorsList"
        class="flex justify-end"
      />
      <div class="overflow-x-auto overflow-y-hidden relative pb-10 md:pb-0">
        <div
          v-if="
            !isStacked &&
            props.trendLine[0].filter(Number).length > 0 &&
            isTrendline
          "
          class="w-full absolute bottom-0 z-[9]"
        >
          <div class="relative">
            <ClientOnly>
              <div v-if="linesData" class="pb-10 md:pb-[25px]">
                <svg
                  :width="svgWidth"
                  :height="svgHeight"
                  :viewBox="`0 0 ${svgWidth} ${svgHeight}`"
                  :style="{
                    width: svgWidth + 'px',
                  }"
                  class="w-full h-auto"
                >
                  <!-- Lines and circles for data points -->
                  <g v-for="(line, index) in linesData" :key="index">
                    <polyline
                      :points="line.points"
                      fill="none"
                      :stroke="line.color"
                      stroke-width="2.5"
                      stroke-dasharray="8"
                    />
                    <g v-for="(point, i) in line.pointsArray" :key="i">
                      <circle
                        :cx="point.x"
                        :cy="point.y"
                        r="12"
                        fill="white"
                        :stroke="line.color"
                        stroke-width="3"
                        :style="{
                          transformOrigin: `${point.x}px ${point.y}px`,
                        }"
                        class="peer cursor-pointer transition"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </ClientOnly>
          </div>
        </div>
        <div
          class="relative md:pb-[25px] break-inside-avoid w-full"
          :class="[
            props.format === 'small' ? 'min-w-[728px]' : 'min-w-[1104px]',
          ]"
        >
          <div class="absolute top-0 left-0 size-full block z-0">
            <div class="flex flex-col-reverse justify-between h-full">
              <div
                v-for="(y, index) in yAxis"
                :key="index"
                class="relative flex-1 h-full flex items-start text-xs mobile-only:!flex-1"
                :style="
                  index === 0
                    ? {
                        flex: '0 0 ' + (10 + 'px'),
                      }
                    : {}
                "
              >
                <div class="pr-1.5 leading-[0.75] flex-1 pt-4 border-t">
                  {{ y.label }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-full text-xs flex flex-row justify-evenly h-[420px] z-10"
          >
            <div
              v-for="(item, i) in items"
              :key="i"
              class="flex flex-col relative"
            >
              <div
                class="flex h-full"
                :class="{
                  'flex-col-reverse': isStacked,
                  'flex-row items-end gap-1': !isStacked,
                }"
              >
                <div
                  v-for="(cell, i) in item.cells"
                  :key="i"
                  :class="{
                    'flex flex-row': !isStacked,
                  }"
                  :style="{
                    position: 'relative',
                    flex: isStacked
                      ? Math.round(cell.value * 10000).toFixed(0)
                      : '',
                    backgroundColor: filteredColorsList[i],
                  }"
                >
                  <div
                    class="text-white font-medium w-11 h-full relative chart-bar-distribution-cell"
                    :style="{
                      height: !isStacked
                        ? Math.round(cell.value * (svgHeight / largestOnAxis)) +
                          'px'
                        : '',

                      backgroundColor: filteredColorsList[i],
                    }"
                  >
                    <div
                      class="absolute left-0 top-0 size-full flex items-center justify-center chart-bar-distribution-cell-value"
                      :class="{
                        'always-show-on-hover': valuesOnHover,
                      }"
                    >
                      <div class="px-0 overflow-hidden">
                        {{ cell.formatted }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="item.total < largestOnAxis"
                  :style="{
                    flex: Math.round(
                      (largestOnAxis - item.total) * 10000,
                    ).toFixed(),
                  }"
                />
              </div>
              <h4
                class="uppercase font-medium absolute bottom-[-25px] w-full text-center"
              >
                {{ item.title }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  getSaneNumber,
  type ChartColorPalette,
} from '~/helpers/charts'

const props = withDefaults(
  defineProps<{
    table: string[][]
    colorPalette: ChartColorPalette
    valuePrefix?: string
    valueSuffix?: string
    valuesOnHover?: boolean
    decimalPoints: number
    axisLabels: string[]
    isStacked?: boolean
    isHidden?: boolean
    isTrendline?: boolean
    trendLine?: string[][]
    format?: string
  }>(),
  {
    valuePrefix: '',
    valueSuffix: '',
    trendLine: () => [],
  },
)
function getFormattedValue(value: number): string {
  const roundedNumber = value.toFixed(props.decimalPoints)
  return `${props.valuePrefix || ''}${roundedNumber}${props.valueSuffix || ''}`
}

function getColor(index: number) {
  return colors.value[index % colors.value.length]
}

// bars

function filterEmpty(v: any) {
  return v !== undefined && v !== null && typeof v === 'string' && v.length
}

const dataPoints = computed(() => {
  return props.table.map((v) => v[0]).filter(filterEmpty)
})

const barLabels = computed(() =>
  props.table[0].map((v) => v).filter(filterEmpty),
)

const dataSets = props.table.slice(1).map((row) => row.slice(1).map(Number))
const flatData = dataSets.flat()

const items = computed(() => {
  return barLabels.value.map((_v, index) => {
    const data = props.table.slice(1).map((values) => {
      return values[index + 1]
    })
    const values = data.map((v) => {
      if (typeof v === 'string') {
        const num = parseFloat(v)
        if (isNaN(num)) {
          return 0
        }

        return num
      }

      return 0
    })
    const total = values.reduce((acc, v) => {
      return acc + v
    }, 0)

    return {
      title: barLabels.value[index],
      total,
      values,
      cells: values.map((value) => {
        return {
          value,
          formatted: getFormattedValue(value),
        }
      }),
    }
  })
})

const largestStacked = computed(() =>
  items.value.reduce((acc, v) => {
    if (v.total > acc) {
      return v.total
    }
    return acc
  }, 0),
)

const largest = computed(() => {
  if (props.isStacked) {
    return largestStacked.value
  }
  return Math.max(...flatData)
})

const largestOnAxis = computed(() => {
  const lastAxis = yAxis.value[yAxis.value.length - 1]?.value
  if (!lastAxis) {
    return 100
  }

  return lastAxis
})

type YAxis = {
  label: string
  value?: number
}

const yAxis = computed<YAxis[]>(() => {
  const range = getSaneNumber(largest.value, false)
  const step = getSaneNumber(range / 8, true)
  const niceMin = 0
  const niceMax = Math.ceil(largest.value / step) * step
  const tickCount = Math.round((niceMax - niceMin) / step) + 1

  const fixed = (step.toString().split('.')[1] || '').length

  return Array.from({ length: tickCount }, (_, i) => {
    const value = i * step
    const valueFixed = value.toFixed(fixed)
    const label = `${props.valuePrefix || ''}${valueFixed}${props.valueSuffix || ''}`
    return { label, value }
  })
})

const colors = computed(() =>
  getColorPalette(props.colorPalette, false, dataPoints.value.length),
)
const filteredColors = computed(() => {
  return Array.from({ length: dataPoints.value.length }, (_, i) => {
    const colorIndex =
      colors.value[
        Math.round((i * colors.value.length) / dataPoints.value.length)
      ]
    return { colorIndex }
  })
})

const filteredColorsList = computed(() => {
  return filteredColors.value.map((colors) => colors.colorIndex)
})

// Trendline

type DataPoint = {
  x: number
  y: number
  value: number
  color: string
}

type DataLine = {
  label: string
  points: string
  pointsArray: DataPoint[]
  color: string
}

const gridContainerSize = computed(() => {
  if (props.format === 'small') {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  }
  return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
})

const svgWidth = computed(() => {
  if (props.format === 'small') {
    return 728
  }
  return 1104
})

const svgHeight = 420

const dataTrendLineSets = props.trendLine?.[0].map(Number)
const labels = props.trendLine?.map((row) => row[0])

const takenSpace =
  dataPoints.value.length * 44 * barLabels.value.length +
  (dataPoints.value.length - 1) * 4 * barLabels.value.length

const colSpace = takenSpace / barLabels.value.length
const colSpaceBetween =
  (svgWidth.value - takenSpace) / (barLabels.value.length + 1)

const linesData = computed<DataLine[]>(() => {
  return labels.map((label, idx) => {
    const color = getColor(idx)
    const pointsArray: DataPoint[] = dataTrendLineSets.map((data, yearIdx) => {
      const x =
        (yearIdx + 1) * colSpaceBetween + yearIdx * colSpace + colSpace / 2

      const y = svgHeight - svgHeight * (data / largestOnAxis.value)
      return { x, y, value: data, color }
    })
    const points = pointsArray.map((point) => point.x + ',' + point.y).join(' ')
    return { label, points, pointsArray, color }
  })
})
</script>

<style lang="postcss">
.chart-bar-distribution-cell {
  container-type: inline-size;
}
.chart-bar-distribution-cell-value {
  &.always-show-on-hover {
    @apply invisible cursor-pointer;
    .chart-bar-distribution-cell:hover & {
      @apply visible;
    }
  }
}
</style>
