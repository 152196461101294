<template>
  <div class="grid-container">
    <div
      :class="[
        isHidden === true ? 'bk-paragraph-region-affected' : '',
        gridContainerSize,
      ]"
    >
      <ParagraphChartBarHorizontalDistribution
        v-if="isDistribution"
        :table="table"
        :distribution="distribution"
        :color-palette="colorPalette"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :values-on-hover="valuesOnHover"
        :decimal-points="decimalPoints"
        :format="format"
      />
      <ParagraphChartBarHorizontalSingle
        v-else
        :table="table"
        :distribution="distribution"
        :color-palette="colorPalette"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :decimal-points="decimalPoints"
        :format="format"
      />
      <div v-if="axisLabel" class="font-medium pt-5 text-center text-base">
        {{ axisLabel }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ChartBarDistribution, ChartColorPalette } from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  valuePrefix?: string
  valueSuffix?: string
  valuesOnHover?: boolean
  decimalPoints: number
  axisLabel?: string
  isHidden?: boolean
  format?: string
}>()

const isDistribution = computed(() => props.table[0]?.length > 2)

const gridContainerSize = computed(() => {
  if (props.format === 'small') {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  }
  return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
})
</script>
