<template>
  <div class="grid gap-20">
    <div v-for="(row, index) in items" :key="index">
      <h4 class="font-medium uppercase text-xs mb-5">{{ row.title }}</h4>
      <div class="flex h-6">
        <div
          class="text-white text-right h-full font-medium text-xs flex items-center justify-end"
          :style="{
            flex: row.value,
            backgroundColor: getColor(),
          }"
        >
          <div class="pr-10">{{ getFormattedValue(row.value) }}</div>
        </div>
        <div
          class="h-full bg-gray-100"
          :style="{
            flex: row.restFlex,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  type ChartBarDistribution,
  type ChartColorPalette,
} from '~/helpers/charts'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  valuePrefix?: string
  valueSuffix?: string
  decimalPoints: number
}>()

function getFormattedValue(value: number): string {
  const roundedNumber = value.toFixed(props.decimalPoints)
  return `${props.valuePrefix || ''}${roundedNumber}${props.valueSuffix || ''}`
}

const rows = computed(() => {
  return props.table
    .map((row) => {
      const title = row[0]
      const valueString = row[1]

      if (typeof title !== 'string' || typeof valueString !== 'string') {
        return null
      }

      const value = parseFloat(valueString)

      if (isNaN(value)) {
        return null
      }

      return {
        title,
        value,
      }
    })
    .filter(falsy)
})

const colors = computed(() => getColorPalette(props.colorPalette, false, 1))

function getColor() {
  return colors.value[0]
}

const maxValue = computed(() => {
  return rows.value.reduce((acc, v) => {
    if (v.value > acc) {
      return v.value
    }

    return acc
  }, 0)
})

const items = computed(() => {
  return rows.value.map((row) => {
    return {
      ...row,
      restFlex:
        props.distribution === 'largest'
          ? maxValue.value - row.value
          : 100 - row.value,
    }
  })
})
</script>
