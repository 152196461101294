<template>
  <div v-if="status" class="cta-outer" :class="backgroundColorClass">
    <CallToActionSection
      :is-breakout="isBreakout"
      :image-alignment="options.imageAlignment"
    >
      <template #image>
        <div v-if="image" class="relative">
          <ImageItem
            v-bind="image.image"
            class="w-full"
            :loading="loading"
            :preload="preload"
            :image-style="usedImageStyle"
          />
          <div
            v-if="image.copyright"
            class="text-right text-gray-400 text-sm mt-1"
          >
            © {{ image.copyright }}
          </div>

          <div
            v-if="hasInfoBox"
            class="w-full lg:w-[400px] absolute bg-gray-900/80 text-white p-8 lg:rounded-[40px] flex flex-col gap-5"
            :class="{
              'top-0 lg:top-7 xl:top-14': options.infoBoxAlignment === 'top',
              'bottom-0 lg:bottom-7 xl:bottom-14':
                options.infoBoxAlignment === 'bottom',
              'lg:right-4 xl:right-14': options.imageAlignment === 'left',
              'lg:left-4 xl:left-14': options.imageAlignment === 'right',
            }"
          >
            <Collapsible
              :id="uuid"
              ref="infoBox"
              :details-class-list="[]"
              :force-open="isEditing"
              summary-class-list="text-xl"
            >
              <template #summary>
                {{ infoBoxTitle }}
              </template>

              <template #details>
                <div class="pt-5" v-html="infoBoxContent" />
              </template>
            </Collapsible>
            <!-- The infoBox variables/methods are defined via defineExpose on Collapsible/index.vue. -->
            <button
              class="flex items-center gap-3 transition-all ease-in-out duration-250"
              :class="{
                '!gap-0': infoBox && infoBox.isOpen && !isEditing,
              }"
              :aria-controls="uuid"
              @click.prevent="infoBox?.toggle()"
            >
              <span
                class="transition-all ease-in-out duration-250 max-w-full lg:max-w-[400px] whitespace-nowrap overflow-hidden"
                :class="{
                  '!max-w-0': infoBox && infoBox.isOpen && !isEditing,
                }"
              >
                {{ $texts('button_learn_more', 'Mehr erfahren') }}
              </span>

              <SpriteSymbol name="add-hexagon" class="w-6 h-[25px]" />
            </button>
          </div>
        </div>
        <div
          v-else-if="video"
          class="relative flex items-center justify-center h-full"
        >
          <Video
            :video="video"
            :video-url="video.url"
            :image-style="usedImageStyle"
            is-breakout
          />
        </div>
      </template>
      <template #content>
        <BlokkliField
          class="content"
          name="field_cta_content_paragraphs"
          :list="paragraphs"
        />
      </template>
    </CallToActionSection>
  </div>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  ParagraphCallToActionSectionFragment,
  VideoFragment,
} from '#graphql-operations'
import type { Collapsible } from '#components'

const props = defineProps<{
  status: ParagraphCallToActionSectionFragment['status']
  uuid: ParagraphCallToActionSectionFragment['uuid']
  media: ParagraphCallToActionSectionFragment['media']
  paragraphs: ParagraphCallToActionSectionFragment['paragraphs']
  infoBoxTitle?: ParagraphCallToActionSectionFragment['infoBoxTitle']
  infoBoxContent?: ParagraphCallToActionSectionFragment['infoBoxContent']
}>()

const { $texts } = useEasyTexts()

const image = computed(() => {
  if (props.media && 'image' in props.media) {
    return props.media as MediaImageFragment
  }
  return null
})

const video = computed(() => {
  if (props.media && 'url' in props.media) {
    return props.media as VideoFragment
  }
  return null
})

const { options, isEditing, index, parentType } = defineBlokkli({
  bundle: 'call_to_action_section',
  options: {
    size: {
      type: 'radios',
      label: 'Grösse',
      default: 'medium',
      displayAs: 'icons',
      options: {
        medium: {
          label: 'Mittel',
          icon: 'icon-blokkli-option-medium',
        },
        large: {
          label: 'Gross',
          icon: 'icon-blokkli-option-large',
        },
      },
    },
    imageAlignment: {
      type: 'radios',
      label: 'Image alignment',
      default: 'left',
      displayAs: 'icons',
      options: {
        left: {
          label: 'Bild links',
          icon: 'icon-blokkli-option-image-left',
        },
        right: {
          label: 'Bild rechts',
          icon: 'icon-blokkli-option-image-right',
        },
      },
    },
    infoBoxAlignment: {
      type: 'radios',
      label: 'Info box alignment',
      default: 'top',
      displayAs: 'icons',
      options: {
        top: {
          label: 'Ausrichtung oben',
          icon: 'icon-blokkli-option-box-top',
        },
        bottom: {
          label: 'Ausrichtung unten',
          icon: 'icon-blokkli-option-box-bottom',
        },
      },
    },
    backgroundColor: {
      type: 'radios',
      label: 'Hintergrundfarbe',
      default: 'lightGray',
      displayAs: 'colors',
      options: {
        lightGray: {
          label: 'Hellgrau',
          hex: '#DCDCDE',
        },
        mediumGray: {
          label: 'Grau',
          hex: '#949BA9',
        },
      },
    },
  },
})

const isBreakout = computed(() => {
  return options.value.size === 'large'
})

const standardImageStyleConfig = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 768,
      aspectRatio: 3 / 2,
    },
    sm: {
      width: 1024,
      aspectRatio: 4 / 3,
    },
    md: {
      width: 1280,
      aspectRatio: 4 / 3,
    },
    lg: {
      width: 690,
      aspectRatio: 4 / 3,
    },
    xl: {
      width: 640,
      aspectRatio: 4 / 3,
    },
  },
})

const breakoutImageStyleConfig = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 768,
      aspectRatio: 4 / 3,
    },
    sm: {
      width: 1024,
      aspectRatio: 4 / 3,
    },
    md: {
      width: 1280,
      aspectRatio: 4 / 3,
    },
    lg: {
      width: 1024,
      aspectRatio: 6 / 5,
    },
    xl: {
      width: 1280,
      aspectRatio: 4 / 3,
    },
  },
})

const usedImageStyle = computed(() => {
  return isBreakout.value ? breakoutImageStyleConfig : standardImageStyleConfig
})

const hasInfoBox = computed(() => {
  return !!props.infoBoxTitle && !!props.infoBoxContent
})

const backgroundColorClass = computed(() => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (options.value.backgroundColor) {
    case 'mediumGray':
      return 'bg-gray-100'
    default:
      return ''
  }
})

const infoBox = ref<typeof Collapsible | null>(null)

let preload = false
// If the index is 0, the image is most probably above the fold
const loading = computed(() => {
  if (index.value === 0 && !parentType.value) {
    preload = true
    return 'eager'
  }
  return 'lazy'
})
</script>

<style lang="postcss">
.cta-inner {
  @apply py-5;
}

:not(.cta-outer) + .cta-outer {
  @apply mt-12;
}

:not(.cta-outer) + .cta-outer.bg-gray-100 {
  @apply pt-4 md:pt-5 overflow-hidden;
}

:not(.cta-outer) + .cta-outer.bg-gray-100 .cta-inner {
  @apply mb-0;
}

.cta-outer:not(:has(+ .cta-outer)) .cta-inner {
  @apply mb-12;
}

.cta-outer.bg-gray-100:not(:has(+ .cta-outer)) .cta-inner {
  @apply pb-10;
}

.cta-outer:not(.bg-gray-100):has(+ .cta-outer.bg-gray-100) {
  @apply pb-5;
}
.cta-outer:not(.bg-gray-100) + .cta-outer.bg-gray-100 {
  @apply pt-5;
}

.cta-outer.bg-gray-100:has(+ .cta-outer:not(.bg-gray-100)) {
  @apply pb-5;
}

.cta-outer.bg-gray-100 + .cta-outer:not(.bg-gray-100) {
  @apply pt-5;
}

.cta-outer:not(:has(+ .cta-outer)) button {
  @apply mb-0;
}
</style>
